<template>
    <div id="campaign-create-wizard"
         ref="createCampaignWizard"
         aria-hidden="true"
         aria-labelledby="dialogCampaignCreateTitle"
         class="modal fade"
         role="dialog"
         tabindex="-1">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header justify-content-between">
                    <button v-if="steps.current > 1"
                            class="border-0 bg-transparent me-2"
                            @click="previousStep()">
                        <span class="sr-only">{{ $t('actions.back') }}</span>
                        <font-awesome-icon icon="arrow-left"/>
                    </button>
                    <h5 class="modal-title">{{ $t('modal.create-campaign.title') }}</h5>
                    <button :aria-label="$t('actions.close')"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            type="button"/>
                </div>
                <div class="modal-body">

                    <div class="modal-wrapper">

                        <div :class="getStepClass(1)" class="step px-3">
                            <div class="row">
                                <div class="col-12">
                                    <h2 class="h5 fw-bold">{{ $t('modal.create-campaign.step-1.title') }}</h2>
                                    <p>{{ $t('modal.create-campaign.step-1.description') }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div v-for="(channel, i) in outboundChannels"
                                         :key="'outbound-channel-' + i"
                                         class="provider-select"
                                         role="button"
                                         @click="onSelectOutboundChannel(channel.channelDescShort, channel.channelUniqueId)">

                                        <div class="d-flex align-items-center p-3">
                                            <img :src="logo.deutschePost" alt="Deutsche Post">
                                            <div class="text-start">
                                                <h5 class="fw-bold">
                                                    {{ $t('modal.create-campaign.step-1.print-mailing-automation-letter.title') }}
                                                </h5>
                                                <p class="mb-0">
                                                    {{ $t('modal.create-campaign.step-1.print-mailing-automation-letter.description') }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div :class="getStepClass(2)" class="step px-3">

                            <div class="position-relative">
                                <!-- CAMPAIGN TYPE TABS -->
                                <ul id="campaign-type"
                                    class="nav nav-tabs mb-3 justify-content-center"
                                    role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button id="pills-manual-tab"
                                                aria-controls="pills-manual"
                                                aria-selected="true"
                                                class="nav-link active"
                                                data-bs-target="#pills-manual"
                                                data-bs-toggle="pill"
                                                role="tab"
                                                type="button"
                                                @click="onChangeCampaignType('CAMPAIGN_REGULAR')">{{ $t('manual') }}
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button id="pills-automated-tab"
                                                aria-controls="pills-automated"
                                                aria-selected="false"
                                                class="nav-link"
                                                data-bs-target="#pills-automated"
                                                data-bs-toggle="pill"
                                                role="tab"
                                                type="button"
                                                @click="onChangeCampaignType('CAMPAIGN_MBOX')">{{ $t('automated') }}
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button id="pills-1-click-tab"
                                                aria-controls="pills-1-click"
                                                aria-selected="false"
                                                class="nav-link"
                                                data-bs-target="#pills-1-click"
                                                data-bs-toggle="pill"
                                                role="tab"
                                                type="button">
                                                {{ $tc('1-click-campaign', 1) }}
                                        </button>
                                    </li>
                                </ul>

                                <!-- CAMPAIGN TYPE CONTENT -->
                                <div id="campaign-typeContent"
                                     class="tab-content">
                                    <!-- MANUAL TAB -->
                                    <div id="pills-manual"
                                         aria-labelledby="pills-manual-tab"
                                         class="tab-pane fade show active"
                                         role="tabpanel">

                                        <div class="row">
                                            <div class="col-12">
                                                <h2 class="h5 mb-3">
                                                    {{ $t('modal.create-campaign.step-2.manual.title') }}
                                                </h2>
                                                <p class="mb-4">
                                                    {{ $t('modal.create-campaign.step-2.manual.description') }}
                                                </p>
                                            </div>
                                        </div>

                                        <!-- MANUAL CAMPAIGN TYPE SUB-TABS -->
                                        <ul id="campaign-type-manual" class="nav nav-tabs mb-3" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <button id="pills-manual-templates-tab"
                                                        aria-controls="pills-manual-template"
                                                        aria-selected="false"
                                                        class="nav-link"
                                                        data-bs-target="#pills-manual-template"
                                                        data-bs-toggle="pill"
                                                        role="tab"
                                                        type="button">
                                                    {{ $tc('template', 2) }}
                                                </button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button id="pills-manual-individual-tab"
                                                        aria-controls="pills-manual-individual"
                                                        aria-selected="true"
                                                        class="nav-link active"
                                                        data-bs-target="#pills-manual-individual"
                                                        data-bs-toggle="pill"
                                                        role="tab"
                                                        type="button"
                                                        @click="onChangeCampaignType('CAMPAIGN_REGULAR')">
                                                    {{ $t('individual') }}
                                                </button>
                                            </li>
                                        </ul>

                                        <div id="campaign-type-manualContent" class="tab-content">
                                            <!-- MANUAL_TEMPLATES SUB-CONTENT -->
                                            <div id="pills-manual-template"
                                                 aria-labelledby="pills-manual-templates-tab"
                                                 class="tab-pane fade"
                                                 role="tabpanel">
                                                <p>{{ $t('modal.create-campaign.step-2.manual.templates.title') }}</p>
                                                <div class="alert alert-info d-flex align-items-center">
                                                    <font-awesome-icon class="ms-2 me-3" icon="info" size="2x"/>
                                                    <span class="fw-bold">
                                                        {{ $t('modal.create-campaign.step-2.manual.templates.description') }}
                                                    </span>
                                                </div>
                                            </div>

                                            <!-- MANUAL_INDIVIDUAL SUB-CONTENT -->
                                            <div id="pills-manual-individual"
                                                 aria-labelledby="pills-manual-individual-tab"
                                                 class="tab-pane fade show active"
                                                 role="tabpanel">
                                                <form @submit.prevent="onSubmit">
                                                    <div class="mb-2">
                                                        <label class="form-label" for="campaign-name">
                                                            <strong>{{ $t('campaign-name') }}</strong>
                                                        </label>
                                                        <input id="campaign-name"
                                                               v-model="selected.name"
                                                               class="form-control"
                                                               maxlength="50"
                                                               required="required"
                                                               type="text">
                                                    </div>
                                                    <button class="btn btn-primary" type="submit">
                                                        {{ $t('actions.continue') }}
                                                    </button>
                                                </form>
                                            </div>
                                        </div>

                                    </div>

                                    <!-- AUTOMATED TAB -->
                                    <div id="pills-automated"
                                         aria-labelledby="pills-automated-tab"
                                         class="tab-pane fade"
                                         role="tabpanel">

                                        <div class="row">
                                            <div class="col-12">
                                                <h2 class="h5 mb-3">
                                                    {{ $t('modal.create-campaign.step-2.automated.title') }}</h2>
                                                <p class="mb-4">
                                                    {{ $t('modal.create-campaign.step-2.automated.description') }}</p>
                                            </div>
                                        </div>

                                        <!-- AUTOMATED CAMPAIGN TYPE SUB-TABS -->
                                        <ul id="campaign-type-automated" class="nav nav-tabs mb-3" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <button id="pills-automated-templates-tab"
                                                        aria-controls="pills-automated-templates"
                                                        aria-selected="false"
                                                        class="nav-link"
                                                        data-bs-target="#pills-automated-templates"
                                                        data-bs-toggle="pill"
                                                        role="tab"
                                                        type="button">{{ $tc('template', 2) }}
                                                </button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button id="pills-automated-individual-tab"
                                                        aria-controls="pills-automated-individual"
                                                        aria-selected="false"
                                                        class="nav-link"
                                                        data-bs-target="#pills-automated-individual"
                                                        data-bs-toggle="pill"
                                                        role="tab"
                                                        type="button"
                                                        @click="onChangeCampaignType('CAMPAIGN_AUTOMATED_EVENT_BASED')">{{ $t('individual') }}
                                                </button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button id="pills-automated-mbox-tab"
                                                        aria-controls="pills-automated-mbox"
                                                        aria-selected="true"
                                                        class="nav-link active"
                                                        data-bs-target="#pills-automated-mbox"
                                                        data-bs-toggle="pill"
                                                        role="tab"
                                                        type="button"
                                                        @click="onChangeCampaignType('CAMPAIGN_MBOX')">MBOX
                                                </button>
                                            </li>
                                        </ul>

                                        <div id="campaign-type-automatedContent" class="tab-content">

                                            <!-- AUTOMATED TEMPLATES SUB-TAB CONTENT -->
                                            <div id="pills-automated-templates"
                                                 aria-labelledby="pills-automated-templates-tab"
                                                 class="tab-pane fade"
                                                 role="tabpanel">
                                                <p>{{ $t('modal.create-campaign.step-2.automated.templates.title') }}</p>
                                                <div class="alert alert-info d-flex align-items-center">
                                                    <font-awesome-icon class="ms-2 me-3" icon="info" size="2x"/>
                                                    <span class="fw-bold">
                                                        {{ $t('modal.create-campaign.step-2.automated.templates.description') }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div id="pills-automated-individual"
                                                 aria-labelledby="pills-automated-individual-tab"
                                                 class="tab-pane fade"
                                                 role="tabpanel">
                                                <p>{{ $t('modal.create-campaign.step-2.automated.individual.title') }}</p>
                                                <form @submit.prevent="onSubmit">
                                                    <div class="mb-2">
                                                        <label class="form-label"
                                                               for="campaign-name-automated-individual">
                                                            <strong>{{ $t('campaign-name') }}</strong>
                                                        </label>
                                                        <input id="campaign-name-automated-individual"
                                                               v-model="selected.name"
                                                               class="form-control"
                                                               maxlength="50"
                                                               required="required"
                                                               type="text">
                                                    </div>
                                                    <button class="btn btn-primary" type="submit">
                                                        {{ $t('actions.continue') }}
                                                    </button>
                                                </form>
                                            </div>
                                            <div id="pills-automated-mbox"
                                                 aria-labelledby="pills-automated-mbox-tab"
                                                 class="tab-pane fade show active"
                                                 role="tabpanel">
                                                <p>{{ $t('modal.create-campaign.step-2.automated.mbox.title') }}</p>
                                                <form @submit.prevent="onSubmit">
                                                    <div class="mb-2">
                                                        <label class="form-label" for="campaign-channel-automated-mbox">
                                                            <strong>MBOX {{ $tc('channel', 1) }}</strong>
                                                        </label>
                                                        <select id="campaign-channel-automated-mbox"
                                                                class="form-select">
                                                            <option v-for="(channel, i) in inboundMboxChannels"
                                                                    :key="'mbox-selection-' + i"
                                                                    :value="channel.channelUniqueId"
                                                                    @change="onSelectInboundChannel(channel.channelDescShort, channel.channelUniqueId)">
                                                                {{ channel.channelDescShort }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="mb-2">
                                                        <label class="form-label" for="campaign-name-automated-mbox">
                                                            <strong>{{ $t('campaign-name') }}</strong>
                                                        </label>
                                                        <input id="campaign-name-automated-mbox"
                                                               v-model="selected.name"
                                                               class="form-control"
                                                               maxlength="50"
                                                               required="required"
                                                               type="text">
                                                    </div>
                                                    <button class="btn btn-primary" type="submit">
                                                        {{ $t('actions.continue') }}
                                                    </button>
                                                </form>
                                            </div>
                                        </div>

                                    </div>



                                    <!-- 1_CLICK_CAMPAIGN -->
                                    <div id="pills-1-click"
                                            aria-labelledby="pills-manual-1-click-campaigns-tab"
                                            class="tab-pane fade"
                                            role="tabpanel">
                                        <p>
                                            {{ $t('modal.create-campaign.step-2.manual.1-click-campaigns.title') }}
                                        </p>
                                        <div class="alert alert-info d-flex align-items-center">
                                            <font-awesome-icon class="ms-2 me-3" icon="info" size="2x"/>
                                            <span class="fw-bold">
                                                {{ $t('modal.create-campaign.step-2.manual.1-click-campaigns.description') }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <component-loading v-if="loading.CAMPAIGN_CREATE" background-color="rgba(255,255,255,.6)"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ComponentLoading from "@/components/ui/ComponentLoading";
import deutschePostLogo from "../../public/img/logo_deutsche_post.svg";
import closeModal from "@/lib/close-modal";

export default {
    name: "ComponentCreateCampaign",
    components: {ComponentLoading},
    computed: {
        loading() {
            return {
                CAMPAIGN_CREATE: this.$store.state.loading.CAMPAIGN_CREATE
            }
        }
    },
    data() {
        return {
            logo: {
                deutschePost: deutschePostLogo
            },
            steps: {
                current: 1,
                max: 2
            },
            events: [],
            selected: {
                name: '',
                tab: 'REGULAR',
                type: 'CAMPAIGN_REGULAR',
                feature: 'SPOTEMOTIONLIFT_CAMPAIGN_REGULAR_MANUAL',
                event: {
                    eventUniqueId: '39224ad6-68a3-4971-99d4-3548abd9b20f',
                    eventDescShort: 'MANUAL'
                },
                inboundChannel: {
                    channelDescShort: null,
                    channelUniqueId: null
                },
                outboundChannel: {
                    channelDescShort: null,
                    channelUniqueId: null
                }
            },
            inboundMboxChannels: [],
            outboundChannels: [],
            channelWebApp: {},
            channelMbox: {},
        }
    },
    methods: {
        nextStep() {
            ++this.steps.current
        },
        previousStep() {
            --this.steps.current;
        },
        getStepClass(step) {
            return {
                active: step === this.steps.current,
                pending: step > this.steps.current,
                complete: step < this.steps.current
            };
        },
        onSubmit() {
            this.$store.dispatch('CAMPAIGN/CREATE', {
                campaignDescShort: this.selected.name,
                campaignDescLong: null,
                campaignComment: null,
                campaignReuseCampaignUniqueId: null,
                campaignType: this.selected.type,
                inboundChannels: [{channelUniqueId: this.selected.inboundChannel.channelUniqueId}],
                outboundChannels: [{channelUniqueId: this.selected.outboundChannel.channelUniqueId}]
            })
                .then(campaign => {
                    closeModal(this.$refs.createCampaignWizard);
                    this.$emit('create', campaign);
                    this.$gtag.event('CAMPAIGN_CREATE');
                })
                .catch(error => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: error.response.data.message
                    });
                });
        },
        onSelectOutboundChannel(channelDescShort, channelUniqueId) {
            this.selected.type = 'CAMPAIGN_REGULAR';
            this.selected.inboundChannel.channelDescShort = this.channelWebApp.channelDescShort;
            this.selected.inboundChannel.channelUniqueId = this.channelWebApp.channelUniqueId;
            this.selected.outboundChannel.channelDescShort = channelDescShort;
            this.selected.outboundChannel.channelUniqueId = channelUniqueId;
            this.nextStep();
        },
        onSelectInboundChannel(channelDescShort, channelUniqueId) {
            this.selected.inboundChannel.channelDescShort = channelDescShort;
            this.selected.inboundChannel.channelUniqueId = channelUniqueId;
        },
        onChangeCampaignType(campaignType) {
            switch (campaignType) {
                case 'CAMPAIGN_REGULAR':
                case 'CAMPAIGN_AUTOMATED_EVENT_BASED':
                    this.selected.type = campaignType;
                    this.selected.inboundChannel.channelDescShort = this.channelWebApp.channelDescShort;
                    this.selected.inboundChannel.channelUniqueId = this.channelWebApp.channelUniqueId;
                    break;
                case 'CAMPAIGN_MBOX':
                    this.selected.type = 'CAMPAIGN_MBOX';
                    this.selected.inboundChannel.channelUniqueId = this.channelMbox.channelUniqueId;
                    this.selected.inboundChannel.channelDescShort = this.channelMbox.channelDescShort;
                    break;
            }
        },
    },
    async beforeMount() {
        let inboundMboxChannels = [];
        let channelMbox = null;
        let channelWebApp = null;

        const inboundChannels = await this.$store.dispatch('CHANNEL/LIST', {
            filter: {'genType.gen_type_desc_short': 'CHANNEL_INBOUND'},
            order: {meta_insert_dt: 'DESC'},
            page: 1,
            itemsPerPage: 25,
        });
        const outboundChannels = await this.$store.dispatch('CHANNEL/LIST', {
            filter: {'genType.gen_type_desc_short': 'CHANNEL_OUTBOUND'},
            order: {meta_insert_dt: 'DESC'},
            page: 1,
            itemsPerPage: 25,
        });

        inboundChannels.list.forEach(channel => {
            if (channel.serviceProvider.serviceProviderDescShort === 'SPOT_EMOTION'
                && channel.serviceProviderService.serviceProviderServiceDescShort === 'MBOX'
            ) {
                if (channelMbox === null) {
                    channelMbox = channel;
                }

                inboundMboxChannels.push(channel);
            } else if (channel.serviceProvider.serviceProviderDescShort === 'SPOT_EMOTION'
                && channel.serviceProviderService.serviceProviderServiceDescShort === 'WEBSERVICE_API'
            ) {
                channelWebApp = channel;
            }
        });

        this.inboundMboxChannels = inboundMboxChannels;
        this.outboundChannels = outboundChannels.list;
        this.channelMbox = channelMbox;
        this.channelWebApp = channelWebApp;
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.modal-wrapper {
    width: 100%;
    height: 70vh;
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    .step {
        transition: margin .3s ease-in-out;
        position: absolute;
        top: 10px;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;

        &.active {
            margin-left: 0;
        }

        &.complete {
            margin-left: -100%;
        }

        &.pending {
            margin-left: 100%;
        }
    }

    .provider-select {
        background-color: $white;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.125);
        transition: all .3s ease-in-out;
        outline: none;
        padding: 0;

        img {
            margin-left: 1.25rem;
            margin-right: 2.5rem;
            align-self: center;
            text-align: center;
            font-size: 3em;
            width: 60px;
        }

        &:hover {
            border-color: $primary;
            background-color: lighten($primary, 61%) !important;
        }
    }
}
</style>
