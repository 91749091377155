<template>
    <layout-full-width :title="$tc('campaign', 2)">
        <component-actions :action-filters="actionFilters"
                           :bulk="bulk"
                           :extended-search="extendedSearch"
                           :general-search-fields="generalSearchFields"
                           :itemsPerPage="itemsPerPage"
                           :page="page"
                           :primary-actions="primaryActions"
                           :secondary-actions="secondaryActions"
                           :total="total"
                           @applyFilters="onApplyFilters"
                           @changeItemsPerPage="onChangeItemsPerPage"
                           @paginate="onChangePage"
                           @refresh="listCampaigns"/>
        <div class="row">
            <div class="col-12">
                <div class="table-responsive pb-5">
                    <table :class="{'table-hover': !loading.CAMPAIGN_LIST && campaigns.total !== 0 }" class="table">
                        <thead>
                        <component-table-header :heading-columns="headingColumns"
                                                :select-all-status="getSelectAllStatus()"
                                                :show-checkbox="true"
                                                @sort="onSortCampaign"
                                                @toggleCheckbox="toggleCheckbox"/>
                        </thead>
                        <tbody>
                        <template v-cloak v-if="!loading.CAMPAIGN_LIST && campaigns.list.length > 0">
                            <tr v-for="(campaign, i) in campaigns.list"
                                :key="campaign.id"
                                class="pointer"
                                @click="onClickCampaign($event, campaign)">
                                <td class="align-middle">
                                    <button class="btn btn-checkbox" @click="toggleCheckbox(i)">
                                        <font-awesome-icon
                                            :class="campaign.selected ? 'text-primary' : ''"
                                            :icon="campaign.selected ? ['fas', 'check-square'] : ['far', 'square']"
                                            class="pe-none"/>
                                    </button>
                                </td>
                                <td class="align-middle">
                                    <strong class="me-1">{{ $tc('identifier', 1) }}:</strong>
                                    <span>{{ campaign.campaignDescShort }}</span>
                                    <br>
                                    <template v-if="campaign.campaignDescLong">
                                        <strong class="me-1">{{ $tc('name', 1) }}:</strong>
                                        <span>{{ campaign.campaignDescLong }}</span>
                                        <br>
                                    </template>
                                    <strong class="me-1">{{ $tc('group', 1) }}:</strong>
                                    <span>{{ $t('single-campaign') }}</span>
                                </td>
                                <td class="align-middle">
                                    <strong class="me-1">{{ $t('inbound') }}:</strong>
                                    <span>{{ campaign.inboundChannels[0].channelDescShort }}</span>
                                    <br>
                                    <strong class="me-1">{{ $t('outbound') }}:</strong>
                                    <span>{{ campaign.outboundChannels[0].channelDescShort }}</span>
                                </td>
                                <td class="align-middle">
                                    <span :class="getCampaignTypeClass(campaign.campaignType)" class="badge">
                                        {{ getCampaignTypeLabel(campaign.campaignType) }}
                                    </span>
                                </td>
                                <td class="align-middle">
                                    <span :class="getCampaignStatusClass(campaign.campaignStatus)"
                                          class="badge">
                                        {{ getCampaignStatusLabel(campaign.campaignStatus) }}
                                    </span>
                                </td>
                                <td class="align-middle">
                                    <template v-if="campaign.metaUpdateDt">
                                        {{ campaign.metaUpdateDt | timestamp }}
                                    </template>
                                    <template v-else>
                                        {{ campaign.metaInsertDt | timestamp }}
                                    </template>
                                </td>
                                <td class="align-middle">
                                    <div class="dropdown">
                                        <button id="campaign-dropdown-actions"
                                                aria-expanded="false"
                                                aria-haspopup="true"
                                                class="btn p-0 bg-transparent"
                                                data-bs-toggle="dropdown"
                                                type="button">
                                            <font-awesome-icon class="pe-none" icon="ellipsis-h"/>
                                        </button>
                                        <div aria-labelledby="campaign-dropdown-actions"
                                             class="dropdown-menu dropdown-menu-end">
                                            <button v-if="campaign.campaignStatus === 'CAMPAIGN_COMPLETED'"
                                                    class="dropdown-item"
                                                    @click="onArchiveCampaign(campaign)">
                                                {{ $t('actions.archive') }}
                                            </button>
                                            <button v-if="['CAMPAIGN_DRAFT', 'CAMPAIGN_ARCHIVED', 'CAMPAIGN_PAUSED'].includes(campaign.campaignStatus)"
                                                    class="dropdown-item"
                                                    data-bs-target="#trash-confirmation"
                                                    data-bs-toggle="modal"
                                                    type="button"
                                                    @click="trash.campaign = campaign">
                                                {{ $t('actions.move-to-trash-bin') }}
                                            </button>
                                            <router-link
                                                v-if="campaign.campaignStatus === 'CAMPAIGN_ACTIVE' || campaign.campaignStatus === 'CAMPAIGN_DRAFT'"
                                                :to="{ name: 'marketing-campaign-settings', params: { id: campaign.campaignUniqueId } }"
                                                class="dropdown-item">
                                                {{ $tc('setting', 2) }}
                                            </router-link>
                                            <a :href="campaign.outboundChannels[0].config.printMailingAutomationUrl"
                                               class="dropdown-item"
                                               rel="noopener noreferrer"
                                               target="_blank">
                                                DP Print-Mailing Automation Dashboard
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-cloak v-if="!loading.CAMPAIGN_LIST && campaigns.list.length === 0">
                            <tr>
                                <td colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('pages.campaigns.no-campaigns-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-if="loading.CAMPAIGN_LIST">
                            <tr v-for="n in itemsPerPage" :key="'row-shimmer-' + n">
                                <td class="align-middle">
                                    <component-shimmer height="14px" style="margin: 4px 0;" width="14px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer style="margin-bottom: 3px;"/>
                                    <component-shimmer width="150px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer style="margin-bottom: 3px;" width="200px"/>
                                    <component-shimmer/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="50px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="50px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="105px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="20px"/>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- Create campaign modal -->
        <component-create-campaign @create="onCreateCampaign"/>

        <!-- Update campaign slide-in -->
        <component-update-campaign :active="update.active"
                                   @close="update.active = false;"
                                   @update="onUpdateCampaign"/>

        <!-- Trash confirmation -->
        <component-trash-confirmation :object="this.trash.campaign"
                                      :title="$t('confirmations.trash.campaign.title')"
                                      @trash="onTrashCampaign"/>

    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "../../layouts/LayoutFullWidth";
import ComponentActions from '../../components/ui/ComponentActions.vue';
import ComponentCreateCampaign from "../../components/ComponentCreateCampaign";
import ComponentShimmer from "../../components/ComponentShimmer";
import ComponentUpdateCampaign from "../../components/ComponentUpdateCampaign";
import shouldOpenSlideIn from "../../lib/should-open-slide-in";
import ComponentTrashConfirmation from "../../components/ui/ComponentTrashConfirmation";
import ComponentTableHeader from '../../components/ui/ComponentTableHeader.vue';
import getCampaignStatusClass from "../../lib/get-campaign-status-class";
import getCampaignStatusLabel from "../../lib/get-campaign-status-label";
import getCampaignTypeClass from "../../lib/get-campaign-type-class";
import getCampaignTypeLabel from "../../lib/get-campaign-type-label";

export default {
    name: "PageCampaigns",
    components: {
        ComponentTrashConfirmation,
        ComponentUpdateCampaign,
        ComponentShimmer,
        ComponentCreateCampaign,
        LayoutFullWidth,
        ComponentActions,
        ComponentTableHeader
    },
    data() {
        return {
            filter: {},
            order: {meta_insert_dt: "DESC"},
            page: 1,
            itemsPerPage: 10,
            limit: null,
            total: 0,
            primaryActions: [
                {
                    text: this.$t('actions.new'),
                    icon: 'plus',
                    type: 'modal',
                    target: '#campaign-create-wizard'
                }
            ],
            secondaryActions: [
                {
                    text: this.$t('actions.show-archived-campaigns'),
                    click: this.onClickShowArchivedCampaigns
                }
            ],
            bulk: false,
            actionFilters: [
                {
                    title: this.$tc('status', 1),
                    key: 'genStatus.gen_status_desc_short',
                    values: [
                        {
                            name: this.$t('statuses.campaign.CAMPAIGN_DRAFT'),
                            value: 'CAMPAIGN_DRAFT'
                        },
                        {
                            name: this.$t('statuses.campaign.CAMPAIGN_ACTIVE'),
                            value: 'CAMPAIGN_ACTIVE'
                        },
                        {
                            name: this.$t('statuses.campaign.CAMPAIGN_PAUSED'),
                            value: 'CAMPAIGN_PAUSED'
                        },
                        {
                            name: this.$t('statuses.campaign.CAMPAIGN_COMPLETED'),
                            value: 'CAMPAIGN_COMPLETED'
                        },
                        {
                            name: this.$t('statuses.campaign.CAMPAIGN_ARCHIVED'),
                            value: 'CAMPAIGN_ARCHIVED'
                        },
                        {
                            name: this.$t('statuses.campaign.CAMPAIGN_IN_PROGRESS'),
                            value: 'CAMPAIGN_IN_PROGRESS'
                        }
                    ]
                },
                {
                    title: this.$tc('type', 1),
                    key: 'genType.gen_type_desc_short',
                    values: [
                        {
                            name: this.$t('types.campaign.CAMPAIGN_REGULAR'),
                            value: 'CAMPAIGN_REGULAR'
                        },
                        {
                            name: this.$t('types.campaign.CAMPAIGN_AUTOMATED_EVENT_BASED'),
                            value: 'CAMPAIGN_AUTOMATED_EVENT_BASED'
                        },
                        {
                            name: this.$t('types.campaign.CAMPAIGN_MBOX'),
                            value: 'CAMPAIGN_MBOX'
                        }
                    ]
                }
            ],
            generalSearchFields: ['gen_campaign_desc_short'],
            extendedSearch: [
                {
                    name: this.$tc('name', 1),
                    technicalName: 'gen_campaign_desc_short'
                }
            ],
            headingColumns: [
                {
                    title: this.$tc('campaign', 1),
                    sortField: 'gen_campaign_desc_short'
                },
                {
                    title: this.$tc('marketing-channel', 2),
                },
                {
                    title: this.$tc('type', 1),
                    sortField: 'genType.gen_type_desc_short'
                },
                {
                    title: this.$tc('status', 1),
                    sortField: 'genStatus.gen_status_desc_short'
                },
                {
                    title: this.$t('modified')
                },
                {
                    title: ""
                }
            ],
            renameCampaignModal: {
                campaignUniqueId: null,
                campaignDescShort: '',
                campaignDescLong: null,
                campaignComment: null,
                campaignType: null,
                campaignStatus: null
            },
            update: {
                active: false
            },
            trash: {
                campaign: {}
            }
        }
    },
    computed: {
        loading() {
            return {
                CAMPAIGN_LIST: this.$store.state.loading.CAMPAIGN_LIST
            }
        },
        campaign() {
            return this.$store.state.CAMPAIGN.campaign;
        },
        campaigns() {
            return this.$store.state.CAMPAIGN.campaigns;
        }
    },
    methods: {
        onChangePage(page) {
            if (page <= 0) {
                return;
            }

            this.page = parseInt(page, 10);
            this.listCampaigns();
        },
        onChangeItemsPerPage(itemsPerPage) {
            if (this.itemsPerPage === itemsPerPage) {
                return;
            }

            this.page = 1;
            this.itemsPerPage = itemsPerPage;
            this.listCampaigns();
        },
        onApplyFilters(filters) {
            this.filter = filters;
            this.listCampaigns();
        },
        onSortCampaign(order) {
            this.order = order;
            this.listCampaigns();
        },
        getFilter() {
            let filter = this.filter;

            if (!Object.hasOwnProperty.call(filter, 'genStatus.gen_status_desc_short')) {
                filter['genStatus.gen_status_desc_short'] = {
                    notIn: ['CAMPAIGN_ARCHIVED', 'CAMPAIGN_TRASHED']
                }
            }

            return filter;
        },
        listCampaigns() {
            const filter = this.getFilter();

            this.$store.dispatch('CAMPAIGN/LIST', {
                filter,
                order: this.order,
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                limit: this.limit,
            })
                .then(campaigns => {
                    this.total = campaigns.total;
                    this.getActionButton();
                });
        },
        async toggleCheckbox(i) {
            if (i === 'ALL') {
                const getSelectAllStatus = this.getSelectAllStatus();
                this.campaigns.list.forEach(campaign => campaign.selected = !getSelectAllStatus);
            } else {
                this.campaigns.list[i].selected = !this.campaigns.list[i].selected;
            }

            this.$forceUpdate();
            this.getActionButton();
        },
        getSelectAllStatus() {
            const campaignCount = this.campaigns.list.length;
            let selectedCampaignCount = 0;

            this.campaigns.list.forEach(campaign => {
                if (campaign.selected) {
                    selectedCampaignCount += 1;
                }
            });

            return campaignCount === selectedCampaignCount && campaignCount !== 0;
        },
        getActionButton() {
            let selectedCampaignCount = 0;

            this.campaigns.list.forEach(campaign => {
                if (campaign.selected) {
                    selectedCampaignCount += 1;
                }
            })

            this.bulk = selectedCampaignCount !== 0;
        },
        getCampaignStatusClass(campaignStatus) {
            return getCampaignStatusClass(campaignStatus);
        },
        getCampaignStatusLabel(campaignStatus) {
            return getCampaignStatusLabel(campaignStatus, this);
        },
        getCampaignTypeClass(campaignType) {
            return getCampaignTypeClass(campaignType);
        },
        getCampaignTypeLabel(campaignType) {
            return getCampaignTypeLabel(campaignType, this);
        },
        onArchiveCampaign(campaign) {
            let mboxEmail = null;

            if (Object.prototype.hasOwnProperty.call(campaign, 'mboxEmail') && campaign.mboxEmail) {
                mboxEmail = campaign.mboxEmail;
            }

            this.$store.dispatch('CAMPAIGN/UPDATE', {
                campaignUniqueId: campaign.campaignUniqueId,
                campaignDescShort: campaign.campaignDescShort,
                campaignDescLong: campaign.campaignDescLong,
                campaignComment: campaign.campaignComment,
                campaignType: campaign.campaignType,
                campaignStatus: 'CAMPAIGN_ARCHIVED',
                mboxEmail,
                inboundChannels: [{channelUniqueId: campaign.inboundChannels[0].channelUniqueId}],
                outboundChannels: [{channelUniqueId: campaign.outboundChannels[0].channelUniqueId}]
            })
                .then(() => {
                    this.$store.dispatch('toast/success', {
                        title: this.$tc('toast.success.success'),
                        message: this.$tc('toast.success.archive-campaign')
                    });
                    this.$gtag.event('CAMPAIGN_UPDATE');
                });
        },
        onTrashCampaign(campaign) {
            let mboxEmail = null;

            if (Object.prototype.hasOwnProperty.call(campaign, 'mboxEmail') && campaign.mboxEmail) {
                mboxEmail = campaign.mboxEmail;
            }

            this.$store.dispatch('CAMPAIGN/UPDATE', {
                campaignUniqueId: campaign.campaignUniqueId,
                campaignDescShort: campaign.campaignDescShort,
                campaignDescLong: campaign.campaignDescLong,
                campaignComment: campaign.campaignComment,
                campaignType: campaign.campaignType,
                campaignStatus: 'CAMPAIGN_TRASHED',
                mboxEmail,
                inboundChannels: [{channelUniqueId: campaign.inboundChannels[0].channelUniqueId}],
                outboundChannels: [{channelUniqueId: campaign.outboundChannels[0].channelUniqueId}]
            })
                .then(() => {
                    this.$store.dispatch('toast/success', {
                        title: this.$t('toast.success.success'),
                        message: this.$t('toast.success.campaign-trashed')
                    });
                    this.$gtag.event('CAMPAIGN_UPDATE');
                    this.listCampaigns();
                });
        },
        onCreateCampaign(campaign) {
            this.$store.dispatch('toast/success', {
                title: this.$tc('toast.success.success'),
                message: this.$tc('toast.success.create-campaign'),
            });
            this.$router.push({name: 'marketing-campaign-settings', params: {id: campaign.campaignUniqueId}});
        },
        onUpdateCampaign() {
            this.$store.dispatch('toast/success', {
                title: this.$tc('toast.success.success'),
                message: this.$tc('toast.success.campaign-updated')
            });
            this.listCampaigns();
        },
        onClickCampaign(event, campaign) {
            if (!shouldOpenSlideIn(event.target)) {
                return;
            }

            this.$store.commit('backdrop/show');
            this.update.active = true;
            this.$store.dispatch('CAMPAIGN/GET', campaign.campaignUniqueId);
        },
        onClickShowArchivedCampaigns() {
            this.filter = {
                'genStatus.gen_status_desc_short': 'CAMPAIGN_ARCHIVED'
            }

            this.listCampaigns()
        }
    },
    beforeMount() {
        this.listCampaigns();
    }
}
</script>

<style lang="scss" scoped></style>
