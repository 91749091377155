<template>
    <component-slide-in :class="{ active }" @close="close">

        <template v-if="!loading.CAMPAIGN_UPDATE && !loading.CAMPAIGN_GET && Object.keys(campaign).length > 0">
            <div class="d-flex align-items-center mb-3">
                <component-avatar>{{ campaign.campaignDescShort.substr(0, 1).toUpperCase() }}</component-avatar>
                <component-slide-in-title :editable="false"
                                          :subtitle="campaign.campaignDescLong"
                                          :title="campaign.campaignDescShort"
                                          :uuid="campaign.campaignUniqueId"
                                          class="ms-3 flex-grow-1"/>
            </div>

            <div class="btn-group btn-group-sm mb-3" role="group">
                <a :href="campaign.outboundChannels[0].config.printMailingAutomationUrl"
                   class="btn btn-outline-dark"
                   rel="noopener noreferrer"
                   target="_blank">
                    <font-awesome-icon class="me-1" icon="external-link-alt"/>
                    Print-Mailing Automation Dashboard
                </a>
                <button v-if="campaign.campaignStatus !== 'CAMPAIGN_ARCHIVED' && campaign.campaignStatus !== 'CAMPAIGN_DRAFT' && campaign.campaignStatus !== 'CAMPAIGN_IN_PROGRESS'"
                        class="btn btn-outline-dark"
                        type="button"
                        @click="onArchiveCampaign">
                    <font-awesome-icon class="me-1" icon="archive"/>
                    {{ $t('actions.archive') }}
                </button>
                <router-link v-if="campaign.campaignStatus === 'CAMPAIGN_ACTIVE' || campaign.campaignStatus === 'CAMPAIGN_DRAFT'"
                             :to="{ name: 'marketing-campaign-settings', params: { id: campaign.campaignUniqueId } }"
                             class="btn btn-outline-dark">
                    <font-awesome-icon class="me-1" icon="cog"/>
                    {{ $tc('setting', 2) }}
                </router-link>
                <router-link v-if="campaign.campaignStatus === 'CAMPAIGN_COMPLETED' || campaign.campaignStatus === 'CAMPAIGN_ARCHIVED' || campaign.campaignStatus === 'CAMPAIGN_IN_PROGRESS' || campaign.campaignStatus === 'CAMPAIGN_ACTIVE'"
                             :to="{name: 'marketing-campaign-log', params: {id: campaign.campaignUniqueId}}"
                             class="btn btn-outline-dark">
                    <font-awesome-icon class="me-1" icon="file-alt"/>
                    {{ $t('actions.view-log') }}
                </router-link>
            </div>

            <div v-if="campaign.campaignStatus === 'CAMPAIGN_ARCHIVED'"
                 class="alert alert-secondary d-flex align-items-center"
                 role="alert">
                <font-awesome-icon class="fa-2x" icon="info-circle" style="margin-right: .75rem"/>
                <span v-html="$t('alerts.info.campaign-is-archived')"/>
            </div>

            <div v-if="campaign.campaignStatus === 'CAMPAIGN_TRASHED'"
                 class="alert alert-danger d-flex align-items-center"
                 role="alert">
                <font-awesome-icon class="fa-2x" icon="info-circle" style="margin-right: .75rem"/>
                <span v-html="$t('alerts.info.campaign-is-trashed')"/>
            </div>

            <div v-if="campaign.campaignStatus === 'CAMPAIGN_COMPLETED'"
                 class="alert alert-success d-flex align-items-center"
                 role="alert">
                <font-awesome-icon class="fa-2x" icon="info-circle" style="margin-right: .75rem"/>
                <span v-html="$t('alerts.info.campaign-is-completed')"/>
            </div>

            <div v-if="campaign.campaignStatus === 'CAMPAIGN_IN_PROGRESS'"
                 class="alert alert-warning d-flex align-items-center"
                 role="alert">
                <font-awesome-icon class="fa-2x" icon="info-circle" style="margin-right: .75rem"/>
                <span v-html="$t('alerts.info.campaign-is-in-progress')"/>
            </div>

            <nav class="nav nav-tabs mb-3">
                <button id="nav-general-tab"
                        aria-controls="nav-general"
                        aria-selected="false"
                        class="nav-link active"
                        data-bs-target="#nav-general"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $t('general') }}
                </button>
                <button id="nav-references-tab"
                        aria-controls="nav-references"
                        aria-selected="false"
                        class="nav-link"
                        data-bs-target="#nav-references"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $tc('reference', 2) }}
                </button>
                <button id="nav-home-tab"
                        aria-controls="nav-comments"
                        aria-selected="true"
                        class="nav-link"
                        data-bs-target="#nav-comments"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $tc('comment', 2) }}
                </button>
                <button id="nav-activity-tab"
                        aria-controls="nav-activity"
                        aria-selected="false"
                        class="nav-link"
                        data-bs-target="#nav-activity"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $tc('activity', 1) }}
                </button>
            </nav>

            <div id="nav-tabContent" class="tab-content">
                <div id="nav-general" aria-labelledby="nav-general-tab" class="tab-pane fade show active"
                     role="tabpanel">
                    <form @submit.prevent="onUpdateCampaign">
                        <div class="row">
                            <div class="col-6">
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="campaign-desc-short">{{ $t('short-name') }}</label>
                                    <input id="campaign-desc-short"
                                           v-model="descShort"
                                           :aria-label="$t('short-name')"
                                           :class="{'is-invalid': this.$v.descShort.$invalid}"
                                           :disabled="!edit.flag"
                                           aria-describedby="campaign-desc-short"
                                           class="form-control form-control-sm"
                                           type="text">
                                    <div v-if="!this.$v.descShort.required" class="invalid-feedback">
                                        {{ $t('validation.error.required.desc-short') }}
                                    </div>
                                    <div v-if="!this.$v.descShort.maxLength" class="invalid-feedback">
                                        {{ $t('validation.error.maxLength.desc-short', {count: $v.descShort.$params.maxLength.max}) }}
                                    </div>
                                </div>
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="campaign-desc-long">{{ $tc('name', 1) }}</label>
                                    <input id="campaign-desc-long"
                                           v-model="descLong"
                                           :aria-label="$tc('name', 1)"
                                           :class="{'is-invalid': this.$v.descLong.$invalid}"
                                           :disabled="!edit.flag"
                                           aria-describedby="campaign-desc-long"
                                           class="form-control form-control-sm"
                                           type="text">
                                    <div v-if="!this.$v.descLong.maxLength" class="invalid-feedback">
                                        {{ $t('validation.error.maxLength.desc-long', {count: $v.descLong.$params.maxLength.max}) }}
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label small"
                                           for="campaign-comment">{{ $tc('description', 1) }}</label>
                                    <textarea id="campaign-comment"
                                              v-model="comment"
                                              :aria-label="$tc('last-name', 1)"
                                              :class="{'is-invalid': this.$v.comment.$invalid}"
                                              :disabled="!edit.flag"
                                              aria-describedby="last-name"
                                              class="form-control form-control-sm"
                                              rows="6"
                                              type="text"></textarea>
                                    <div v-if="!this.$v.comment.maxLength" class="invalid-feedback">
                                        {{ $t('validation.error.maxLength.comment', {count: $v.comment.$params.maxLength.max}) }}
                                    </div>
                                </div>

                                <p>
                                    <button v-if="!edit.flag"
                                            class="btn btn-sm btn-outline-primary me-2"
                                            type="button"
                                            :disabled="campaign.campaignStatus === 'CAMPAIGN_IN_PROGRESS'"
                                            @click="onClickEdit">
                                        {{ $t('actions.edit') }}
                                    </button>
                                    <button v-if="edit.flag"
                                            :disabled="this.$v.$invalid"
                                            class="btn btn-sm btn-outline-success me-2">
                                        {{ $t('actions.update') }}
                                    </button>
                                    <button v-if="edit.flag"
                                            class="btn btn-sm btn-outline-danger"
                                            type="button"
                                            @click="edit.flag = false">
                                        {{ $t('actions.cancel') }}
                                    </button>
                                </p>
                            </div>
                            <div class="col-6">
                                <div class="mb-1">
                                    <span class="d-inline-block small mb-2">{{ $tc('group', 1) }}</span>
                                    <span class="disabled-input">{{ $t('single-campaign') }}</span>
                                </div>
                                <div v-if="campaign.inboundChannels.length > 0" class="mb-1">
                                    <span class="d-inline-block small mb-2">
                                        {{ $tc('inbound-marketing-channel', 1) }}
                                    </span>
                                    <span class="disabled-input">
                                        {{ campaign.inboundChannels[0].serviceProviderService.serviceProvider.serviceProviderDescLong }}
                                        &middot;
                                        {{ campaign.inboundChannels[0].serviceProviderService.serviceProviderServiceDescLong }}
                                    </span>
                                </div>
                                <div v-if="campaign.outboundChannels.length > 0" class="mb-1">
                                    <span class="d-inline-block small mb-2">
                                        {{ $tc('outbound-marketing-channel', 1) }}
                                    </span>
                                    <span class="disabled-input">
                                        {{ campaign.outboundChannels[0].serviceProviderService.serviceProvider.serviceProviderDescLong }}
                                        &middot;
                                        {{ campaign.outboundChannels[0].serviceProviderService.serviceProviderServiceDescLong }}
                                    </span>
                                </div>
                                <div class="mb-1">
                                    <span class="d-inline-block small mb-2">{{ $tc('type', 1) }}</span>
                                    <span class="disabled-input">
                                        {{ $t('types.campaign.' + campaign.campaignType) }}
                                    </span>
                                </div>
                                <div class="mb-1">
                                    <span class="d-inline-block small mb-2">{{ $tc('status', 1) }}</span>
                                    <span class="disabled-input">
                                        {{ $t('statuses.campaign.' + campaign.campaignStatus) }}
                                    </span>
                                </div>
                                <div class="mb-1">
                                    <span class="d-inline-block small mb-2">{{ $t('modified') }}</span>
                                    <span v-if="campaign.metaUpdateDt"
                                          class="disabled-input">{{ campaign.metaUpdateDt | timestamp }}</span>
                                    <span v-else
                                          class="disabled-input">{{ campaign.metaInsertDt | timestamp }}</span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div id="nav-references" aria-labelledby="nav-references-tab" class="tab-pane fade" role="tabpanel">
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                            <tr>
                                <td class="border-bottom-0" colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('no-references-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="nav-comments" aria-labelledby="nav-contact-tab" class="tab-pane fade" role="tabpanel">
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                            <tr>
                                <td class="border-bottom-0" colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('no-comments-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="nav-activity" aria-labelledby="nav-activity-tab" class="tab-pane fade" role="tabpanel">
                    <div class="list-group">
                        <div v-if="campaign.metaUpdateDt" class="list-group-item">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <p class="mb-0">{{ $t('activity-logs.campaign-updated') }}</p>
                                <small>{{ campaign.metaUpdateDt | timestamp }}</small>
                            </div>
                        </div>
                        <div class="list-group-item">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <p class="mb-0">{{ $t('activity-logs.campaign-created') }}</p>
                                <small>{{ campaign.metaInsertDt | timestamp }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-else>
            <div class="d-flex align-items-center mb-3">
                <component-shimmer class="rounded-circle d-block me-3" height="64px" width="64px"/>
                <div>
                    <component-shimmer class="mb-1"/>
                    <component-shimmer width="241.8px"/>
                </div>
            </div>

            <div class="d-flex mb-3">
                <component-shimmer class="me-2" height="37.2px" width="25%"/>
                <component-shimmer class="me-2" height="37.2px" width="25%"/>
                <component-shimmer class="me-2" height="37.2px" width="25%"/>
                <component-shimmer class="me-2" height="37.2px" width="25%"/>
            </div>
        </template>

    </component-slide-in>
</template>

<script>
import ComponentSlideIn from "@/components/ui/ComponentSlideIn";
import ComponentShimmer from "@/components/ComponentShimmer";
import ComponentAvatar from "@/components/ui/ComponentAvatar";
import ComponentSlideInTitle from "@/components/ui/ComponentSlideInTitle";
import {maxLength, required} from "vuelidate/lib/validators";

export default {
    name: "ComponentUpdateCampaign",
    components: {
        ComponentSlideInTitle,
        ComponentAvatar,
        ComponentShimmer,
        ComponentSlideIn
    },
    computed: {
        v() {
            return this.$v;
        },
        loading() {
            return {
                CAMPAIGN_GET: this.$store.state.loading.CAMPAIGN_GET,
                CAMPAIGN_UPDATE: this.$store.state.loading.CAMPAIGN_UPDATE
            }
        },
        campaign() {
            return this.$store.state.CAMPAIGN.campaign;
        },
        descShort: {
            get() {
                return this.edit.flag ? this.edit.descShort : this.campaign.campaignDescShort;
            },
            set(campaignDescShort) {
                this.edit.descShort = campaignDescShort;
            }
        },
        descLong: {
            get() {
                return this.edit.flag ? this.edit.descLong : this.campaign.campaignDescLong;
            },
            set(campaignDescLong) {
                this.edit.descLong = campaignDescLong;
            }
        },
        comment: {
            get() {
                return this.edit.flag ? this.edit.comment : this.campaign.campaignComment;
            },
            set(campaignComment) {
                this.edit.comment = campaignComment;
            }
        },
        variables() {
            if (this.campaign.config) {
                return this.campaign.config.variables;
            }

            return [];
        },
        inboundChannels() {
            if (this.campaign && this.campaign.inboundChannels.length > 0) {
                return [
                    {
                        channelUniqueId: this.campaign.inboundChannels[0].channelUniqueId
                    }
                ];
            }

            return null;
        },
        outboundChannels() {
            if (this.campaign && this.campaign.outboundChannels.length > 0) {
                return [
                    {
                        channelUniqueId: this.campaign.outboundChannels[0].channelUniqueId
                    }
                ];
            }

            return null;
        }
    },
    data() {
        return {
            edit: {
                flag: false,
                descShort: '',
                descLong: '',
                comment: ''
            }
        }
    },
    props: {
        active: {
            Type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        onUpdateCampaign() {
            let mboxEmail = null;

            if (Object.prototype.hasOwnProperty.call(this.campaign, 'mboxEmail') && this.campaign.mboxEmail) {
                mboxEmail = this.campaign.mboxEmail;
            }

            this.$store.dispatch('CAMPAIGN/UPDATE', {
                campaignUniqueId: this.campaign.campaignUniqueId,
                campaignDescShort: this.descShort,
                campaignDescLong: this.descLong,
                campaignComment: this.comment,
                campaignType: this.campaign.campaignType,
                campaignStatus: this.campaign.campaignStatus,
                mboxEmail,
                inboundChannels: [{channelUniqueId: this.campaign.inboundChannels[0].channelUniqueId}],
                outboundChannels: [{channelUniqueId: this.campaign.outboundChannels[0].channelUniqueId}]
            })
                .then(campaign => {
                    this.edit.flag = false;
                    this.$emit('update', campaign);
                    this.$gtag.event('CAMPAIGN_UPDATE');
                })
                .catch(error => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: error.response.data.message
                    });
                });
        },
        onArchiveCampaign() {
            let mboxEmail = null;

            if (Object.prototype.hasOwnProperty.call(this.campaign, 'mboxEmail') && this.campaign.mboxEmail) {
                mboxEmail = this.campaign.mboxEmail;
            }

            this.$store.dispatch('CAMPAIGN/UPDATE', {
                campaignUniqueId: this.campaign.campaignUniqueId,
                campaignDescShort: this.campaign.campaignDescShort,
                campaignDescLong: this.campaign.campaignDescLong,
                campaignComment: this.campaign.campaignComment,
                campaignType: this.campaign.campaignType,
                campaignStatus: 'CAMPAIGN_ARCHIVED',
                mboxEmail,
                inboundChannels: [{channelUniqueId: this.campaign.inboundChannels[0].channelUniqueId}],
                outboundChannels: [{channelUniqueId: this.campaign.outboundChannels[0].channelUniqueId}]
            })
                .then(() => {
                    this.$store.dispatch('toast/success', {
                        title: this.$tc('toast.success.success'),
                        message: this.$tc('toast.success.archive-campaign')
                    });
                    this.$gtag.event('CAMPAIGN_UPDATE');
                });
        },
        onClickEdit() {
            this.edit.descShort = this.campaign.campaignDescShort;
            this.edit.descLong = this.campaign.campaignDescLong;
            this.edit.comment = this.campaign.campaignComment;
            this.edit.flag = true;
        },
        getVariableClass(i) {
            return {
                'border-bottom-0': i < (this.variables.length - 1),
                'rounded-bottom-0': i < (this.variables.length - 1)
            }
        },
        close() {
            this.$store.commit('backdrop/hide');
            this.$emit('close');
        },
        closeOnEscape(e) {
            if (e.key === 'Escape' && this.active === true) {
                this.close();
            }
        }
    },
    validations: {
        descShort: {
            maxLength: maxLength(50),
            required
        },
        descLong: {
            maxLength: maxLength(255)
        },
        comment: {
            maxLength: maxLength(4000)
        },
    },
    watch: {
        active(newValue) {
            if (newValue === true) {
                window.addEventListener('keydown', this.closeOnEscape);
            } else {
                window.removeEventListener('keydown', this.closeOnEscape);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.rounded-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

</style>
